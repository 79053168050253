import React from "react";
import "./Banner.css"; // Your custom styles
import banner1 from "../../Asset/banner1.jpg"; // Assuming these images are in a folder called 'images'
import banner2 from "../../Asset/banner2.jpg";
import banner1s from "../../Asset/banner1s.png";
import banner2s from "../../Asset/banner2s.png";

function Banner() {
  return (
    <div className="banner-container">
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={banner1}
              className="d-md-block d-none w-100 textback"
              alt="Coppersmith"
            />
            <img
              src={banner1s}
              className="d-block d-md-none w-100 textback"
              alt="Coppersmith"
            />
          </div>
          <div className="carousel-item active">
            <img
              src={banner2}
              className="d-md-block d-none w-100 textback"
              alt="Coppersmith"
            />
            <img
              src={banner2s}
              className="d-block d-md-none w-100 textback"
              alt="Coppersmith"
            />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Banner;
