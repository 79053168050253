import "./App.css";
import Banner from "./Component/Banner/Banner";
import Newproducts from "./Component/Newproducts/Newproducts";
import Featuredproducts from "./Component/Featuredproducts/Featuredproducts";
import Sportswear from "./Component/Featuredproducts/Sportswear";
import Clientpage from "./Component/Clientpage/Clientpage";
import Cardswipe from "./Component/Cardswipe/Cardswipe";
import Footer from "./Component/Footer/Footer";
import Menubar from "./Component/Menubar/Menubar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FeatureOverviewPage from "./Component/Featuredproducts/FeatureOverviewPage";
import NewOverviewPage from "./Component/Newproducts/NewOverviewPage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={[
              <Menubar />,
              <Banner />,
              <Newproducts />,
              <Sportswear />,
              <Featuredproducts />,
              <Clientpage />,
              <Cardswipe />,
              <Footer />,
            ]}
          />
          <Route
            path="/detailfeaproduct/:id"
            element={[<Menubar />, <FeatureOverviewPage />, <Footer />]}
          />
          <Route
            path="/detailnewproduct/:id"
            element={[<Menubar />, <NewOverviewPage />, <Footer />]}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
