import React from "react";
import "./Newproducts.css";
import product from "./NewProducts.json";
import { Link } from "react-router-dom";

function Newproducts() {
  return (
    <div className="container-fluid text-center">
      <h1 className="prdheading text-center my-4">New Products</h1>
      <div className="row productbox">
        {" "}
        {/* Ensure 'row' is inside productbox */}
        {product.map((e) => (
          <div key={e.id} className="col-6 col-lg-3 mb-4">
            <Link style={{textDecoration:"none"}} to={`/detailnewproduct/${e.id}`}>
              <div className="text-light">
                <img src={e.image[0]} alt={e.name} />
                <p className="prdname">{e.name}</p>
                <p className="cost">Rs. {e.price}</p>
                <button className="ADDBTN">ADD TO CART</button>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Newproducts;
