import React from "react";
import "./Featuredproducts.css";
import products from "./FeaturedProducts.json";
import { Link } from "react-router-dom";

function Featuredproducts() {
  return (
    <div className="container-fluid text-center">
      <h1 className="prdheading text-center my-4">Featured Products</h1>
      <div className="row my-5 py-5 productbox">
        {products.map((e) => (
          <div className="col-6 col-lg-3 mb-4">
            <Link
              style={{ textDecoration: "none" }}
              to={`/detailfeaproduct/${e.id}`}
            >
              <img src={e.image[0]} alt="Stylish Full Sleeves top" />
              <p className="prdname">{e.name}</p>
              <p className="cost">Rs.{e.price}</p>
              <button className="ADDBTN">ADD TO CART</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Featuredproducts;
