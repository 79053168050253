import React, { useState } from "react";
import { useParams } from "react-router-dom";
import productData from "./FeaturedProducts.json"; // JSON data
import "./FeatureOverviewPage.css"; // Link to your CSS file for styles

function FeatureOverviewPage() {
  const { id } = useParams(); // Get the product ID from the URL
  const product = productData.find((p) => p.id === parseInt(id)); // Find the product by ID

  const [selectedImage, setSelectedImage] = useState(product?.image[0]);

  if (!product) {
    return <p>Product not found!</p>;
  }

  return (
    <div className="container product-page">
      {/* Breadcrumb */}
      <div className="breadcrumb mb-4">
        <a href="/">Home</a> / <span>{product.name}</span>
      </div>

      <div className="row">
        {/* Product Image Section */}
        <div className="col-lg-6 col-md-6">
          <div className="main-product-image mb-4">
            <img src={selectedImage} alt={product.name} className="img-fluid" />
          </div>
          <div className="product-thumbnails d-flex">
            {product.image.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail-img me-2 ${
                  selectedImage === img ? "active-thumbnail" : ""
                }`}
                onClick={() => setSelectedImage(img)}
              />
            ))}
          </div>
        </div>

        {/* Product Details Section */}
        <div className="col-lg-6 col-md-6 product-details">
          <h2>{product.name}</h2>
          <p className="product-price">Rs. {product.price}</p>

          {/* Stock Information */}
          <div className="product-stock-status mb-3">
            <span
              className={`badge ${
                product.inStock ? "badge-success" : "badge-danger"
              }`}
            >
              {product.inStock ? "IN STOCK" : "OUT OF STOCK"}
            </span>
          </div>

          {/* Quantity Selector and Add to Cart Button */}
          <div className="product-actions">
            <div className="quantity-selector d-flex align-items-center mb-3">
              <label htmlFor="quantity" className="me-2">
                Qty:
              </label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                min="1"
                max="10"
                defaultValue="1"
              />
            </div>
            <button className="btn btn-primary add-to-cart">ADD TO CART</button>
          </div>

          {/* Additional Product Actions */}
          <div className="additional-actions mt-3">
            <button className="btn btn-outline-secondary me-2">
              Add to Wishlist
            </button>
            <button className="btn btn-outline-secondary me-2">
              Add to Compare
            </button>
            <button className="btn btn-outline-secondary">Email</button>
          </div>

          {/* Product Description */}
          <div className="product-description mt-4">
            <h4>Overview</h4>
            <p>{product.description}</p>
          </div>
        </div>
      </div>

      {/* Related Products Section */}
      <div className="related-products-section mt-5">
        <h4>Related Products</h4>
        <div className="row">
          {product.Related_Products && product.Related_Products.length > 0 ? (
            product.Related_Products.map((relatedProduct, index) => (
              <div key={index} className="col-lg-4 col-md-6 mb-4">
                <div className="related-product-card card h-100">
                  <img
                    src={relatedProduct.image}
                    alt={`Related Product ${index + 1}`}
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{relatedProduct.name}</h5>
                    <p className="card-text">
                      Price: Rs. {relatedProduct.price}
                    </p>
                    <button className="btn btn-primary">Add to Cart</button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No related products available at the moment.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FeatureOverviewPage;
