import React from "react";
import "./Clientpage.css";
function Clientpage() {
  return (
    <div className="container-fluid ">
      <div className="mx-5 px-5"></div>
    </div>
  );
}

export default Clientpage;
